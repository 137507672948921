import { callWithNuxt, useNuxtApp } from '#app'
import { useTripStore } from '@/store/trip'
import { useUserStore } from '@/store/user'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const tripStore = useTripStore()
  const userStore = useUserStore()

  const nuxtApp = useNuxtApp()
  const { status, signIn, signOut } = useAuth()
  if (status.value === 'authenticated') {
    userStore.getUser()
      .then((response) => {
        userStore.setUserComplete(response?.data?.is_complete)
      }).catch((error) => {
        console.log('error ', error);
      })
  }

  // Check route on trip
  if (!userStore.isComplete && status.value === 'authenticated' && !to.fullPath.includes('user/edit')) {
    return navigateTo('/user/edit')
  }
  if (to.fullPath.includes('trip/')) {
    const match = to.fullPath.match(/\/trip\/([^&]*)/)
    tripStore.setBuyTripIdEnter(match[1])
  }

  // if (status.value === 'unauthorized') {
  //   signOut()
  // }
  // if (status.value === 'unauthenticated') {
  //   console.log('auth.global.ts unauthenticated')
  //   signOut()
  // }
 })
