class Trip {
  constructor () {
  }

  async getCurrentStateBuy (accessToken, BASE_URL) {
    return await $fetch(`${BASE_URL}/api/buy`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json'
      }
    })
  }

  async getTripStep (payload, accessToken, BASE_URL) {
    if (useAuth().status.value == 'unauthenticated') {
      useAuth().signOut()
    }
    return await $fetch(`${BASE_URL}/api/buy`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json'
      },
      body: payload
    })
  }

  async getTripStepBack (payload, accessToken, BASE_URL) {
    return await $fetch(`${BASE_URL}/api/buy/step-back`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json'
      },
      body: payload
    })
  }

  async deleteTripDiscount (payload, accessToken, BASE_URL) {
    return await $fetch(`${BASE_URL}/api/ticket/withdraw`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json'
      },
      body: payload
    })
  }

  async getTripDiscount (payload, accessToken, BASE_URL) {
    return await $fetch(`${BASE_URL}/api/ticket/apply`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json'
      },
      body: payload
    })
  }
}

export default new Trip()
