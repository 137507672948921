class User {
  constructor () {
  }

  async getUser (accessToken, BASE_URL) {
    return await $fetch(`${BASE_URL}/api/user`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json'
      }
    })
  }

  async updateUser (payload, accessToken, BASE_URL) {
    return await $fetch(`${BASE_URL}/api/user/update`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json'
      },
      body: payload
    })
  }
}
export default new User()
