import { default as activatefo9TemY2tNMeta } from "/home/ploi/zapisy.new.kayotravel.pl/pages/auth/activate.vue?macro=true";
import { default as conditionsd9QknrMrbQMeta } from "/home/ploi/zapisy.new.kayotravel.pl/pages/auth/conditions.vue?macro=true";
import { default as forgot_45passwordUPhLx0d9O2Meta } from "/home/ploi/zapisy.new.kayotravel.pl/pages/auth/forgot-password.vue?macro=true";
import { default as loginED3qjizh0TMeta } from "/home/ploi/zapisy.new.kayotravel.pl/pages/auth/login.vue?macro=true";
import { default as registervJwyz3ID7XMeta } from "/home/ploi/zapisy.new.kayotravel.pl/pages/auth/register.vue?macro=true";
import { default as regulationsKOpn0lCc2zMeta } from "/home/ploi/zapisy.new.kayotravel.pl/pages/auth/regulations.vue?macro=true";
import { default as reset_45passwordoFdo7jKt26Meta } from "/home/ploi/zapisy.new.kayotravel.pl/pages/auth/reset-password.vue?macro=true";
import { default as indexD6iPxq54YlMeta } from "/home/ploi/zapisy.new.kayotravel.pl/pages/index.vue?macro=true";
import { default as _91id_93DrZgIGmTdjMeta } from "/home/ploi/zapisy.new.kayotravel.pl/pages/trip/[id].vue?macro=true";
import { default as edit_45passwordCmIjUCkeyQMeta } from "/home/ploi/zapisy.new.kayotravel.pl/pages/user/edit-password.vue?macro=true";
import { default as editKXaAk4nEyNMeta } from "/home/ploi/zapisy.new.kayotravel.pl/pages/user/edit.vue?macro=true";
export default [
  {
    name: activatefo9TemY2tNMeta?.name ?? "auth-activate",
    path: activatefo9TemY2tNMeta?.path ?? "/auth/activate",
    meta: activatefo9TemY2tNMeta || {},
    alias: activatefo9TemY2tNMeta?.alias || [],
    redirect: activatefo9TemY2tNMeta?.redirect,
    component: () => import("/home/ploi/zapisy.new.kayotravel.pl/pages/auth/activate.vue").then(m => m.default || m)
  },
  {
    name: conditionsd9QknrMrbQMeta?.name ?? "auth-conditions",
    path: conditionsd9QknrMrbQMeta?.path ?? "/auth/conditions",
    meta: conditionsd9QknrMrbQMeta || {},
    alias: conditionsd9QknrMrbQMeta?.alias || [],
    redirect: conditionsd9QknrMrbQMeta?.redirect,
    component: () => import("/home/ploi/zapisy.new.kayotravel.pl/pages/auth/conditions.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordUPhLx0d9O2Meta?.name ?? "auth-forgot-password",
    path: forgot_45passwordUPhLx0d9O2Meta?.path ?? "/auth/forgot-password",
    meta: forgot_45passwordUPhLx0d9O2Meta || {},
    alias: forgot_45passwordUPhLx0d9O2Meta?.alias || [],
    redirect: forgot_45passwordUPhLx0d9O2Meta?.redirect,
    component: () => import("/home/ploi/zapisy.new.kayotravel.pl/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: loginED3qjizh0TMeta?.name ?? "auth-login",
    path: loginED3qjizh0TMeta?.path ?? "/auth/login",
    meta: loginED3qjizh0TMeta || {},
    alias: loginED3qjizh0TMeta?.alias || [],
    redirect: loginED3qjizh0TMeta?.redirect,
    component: () => import("/home/ploi/zapisy.new.kayotravel.pl/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: registervJwyz3ID7XMeta?.name ?? "auth-register",
    path: registervJwyz3ID7XMeta?.path ?? "/auth/register",
    meta: registervJwyz3ID7XMeta || {},
    alias: registervJwyz3ID7XMeta?.alias || [],
    redirect: registervJwyz3ID7XMeta?.redirect,
    component: () => import("/home/ploi/zapisy.new.kayotravel.pl/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: regulationsKOpn0lCc2zMeta?.name ?? "auth-regulations",
    path: regulationsKOpn0lCc2zMeta?.path ?? "/auth/regulations",
    meta: regulationsKOpn0lCc2zMeta || {},
    alias: regulationsKOpn0lCc2zMeta?.alias || [],
    redirect: regulationsKOpn0lCc2zMeta?.redirect,
    component: () => import("/home/ploi/zapisy.new.kayotravel.pl/pages/auth/regulations.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordoFdo7jKt26Meta?.name ?? "auth-reset-password",
    path: reset_45passwordoFdo7jKt26Meta?.path ?? "/auth/reset-password",
    meta: reset_45passwordoFdo7jKt26Meta || {},
    alias: reset_45passwordoFdo7jKt26Meta?.alias || [],
    redirect: reset_45passwordoFdo7jKt26Meta?.redirect,
    component: () => import("/home/ploi/zapisy.new.kayotravel.pl/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: indexD6iPxq54YlMeta?.name ?? "index",
    path: indexD6iPxq54YlMeta?.path ?? "/",
    meta: indexD6iPxq54YlMeta || {},
    alias: indexD6iPxq54YlMeta?.alias || [],
    redirect: indexD6iPxq54YlMeta?.redirect,
    component: () => import("/home/ploi/zapisy.new.kayotravel.pl/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93DrZgIGmTdjMeta?.name ?? "trip-id",
    path: _91id_93DrZgIGmTdjMeta?.path ?? "/trip/:id()",
    meta: _91id_93DrZgIGmTdjMeta || {},
    alias: _91id_93DrZgIGmTdjMeta?.alias || [],
    redirect: _91id_93DrZgIGmTdjMeta?.redirect,
    component: () => import("/home/ploi/zapisy.new.kayotravel.pl/pages/trip/[id].vue").then(m => m.default || m)
  },
  {
    name: edit_45passwordCmIjUCkeyQMeta?.name ?? "user-edit-password",
    path: edit_45passwordCmIjUCkeyQMeta?.path ?? "/user/edit-password",
    meta: edit_45passwordCmIjUCkeyQMeta || {},
    alias: edit_45passwordCmIjUCkeyQMeta?.alias || [],
    redirect: edit_45passwordCmIjUCkeyQMeta?.redirect,
    component: () => import("/home/ploi/zapisy.new.kayotravel.pl/pages/user/edit-password.vue").then(m => m.default || m)
  },
  {
    name: editKXaAk4nEyNMeta?.name ?? "user-edit",
    path: editKXaAk4nEyNMeta?.path ?? "/user/edit",
    meta: editKXaAk4nEyNMeta || {},
    alias: editKXaAk4nEyNMeta?.alias || [],
    redirect: editKXaAk4nEyNMeta?.redirect,
    component: () => import("/home/ploi/zapisy.new.kayotravel.pl/pages/user/edit.vue").then(m => m.default || m)
  }
]