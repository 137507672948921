<script lang="ts" setup>
import { ElConfigProvider } from 'element-plus'
import pl from 'element-plus/dist/locale/pl.mjs'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { useTripStore } from '@/store/trip'

dayjs.Ls.en.weekStart = 1

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Europe/Warsaw')

const tripStore = useTripStore()
if (typeof window !== 'undefined') {
  tripStore.updateBuyTripIdEnter()
}
</script>

<template>
  <el-config-provider :locale="pl">
    <el-config-provider namespace="kj">
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
    </el-config-provider>
  </el-config-provider>
</template>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  transform: translate(0px, 50px);
}

.layout-enter-active,
.layout-leave-active {
  transition: all 0.4s;
}
.layout-enter-from,
.layout-leave-to {
  transform: translate(0px, 50px);
}
</style>
