<script lang="ts" setup>

definePageMeta({
  layout: 'register-layout',
  auth: false
})
useHead(() => ({
  title: 'Kayo'
}))

// const error = useError()

defineProps(
  ['error']
)

const handleClearError = () => { clearError({ redirect: '/' }) }
</script>

<template>
  <el-main>
    <div class="container">
      <div id="notfound">
        <div class="notfound">
          <img src="@/assets/images/kayo-logo.png" alt="Kayo logo">
          <div class="notfound-404">
            <h3>Oops! Page not found</h3>
            <h1><span>{{ error.statusCode }}</span></h1>
          </div>
          <h2>{{ error.message }}</h2>
          <el-button @click="handleClearError">
            Go Home
          </el-button>
        </div>
      </div>
    </div>
  </el-main>
</template>

<style>
* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
body {
    padding: 0;
    margin: 0;
}
#notfound {
    position: relative;
    height: 100vh;
}
#notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.notfound {
    max-width: 520px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
}
.notfound .notfound-404 {
    position: relative;
    height: 240px;
}
.notfound .notfound-404 h1 {
    font-family: montserrat, sans-serif;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 252px;
    font-weight: 700;
    margin: 0;
    color: #262626;
    text-transform: uppercase;
    letter-spacing: -40px;
    margin-left: -20px;
}
.notfound .notfound-404 h1 > span {
    text-shadow: -8px 0 0 #fff;
}
.notfound .notfound-404 h3 {
    font-family: cabin, sans-serif;
    position: relative;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #262626;
    margin: 0;
    letter-spacing: 3px;
    padding-left: 6px;
}
.notfound h2 {
    font-family: cabin, sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    color: #000;
    margin-top: 0;
    margin-bottom: 25px;
}
@media only screen and (max-width: 767px) {
    .notfound .notfound-404 {
        height: 200px;
    }
    .notfound .notfound-404 h1 {
        font-size: 200px;
    }
}
@media only screen and (max-width: 480px) {
    .notfound .notfound-404 {
        height: 162px;
    }
    .notfound .notfound-404 h1 {
        font-size: 162px;
        height: 150px;
        line-height: 162px;
    }
    .notfound h2 {
        font-size: 16px;
    }
}
.el-button {
    height: 47px;
    background: #000;
    border-color: #000;
    font-weight: 300;
    border: 3px solid #000;
    border-radius: 10px;
    font-size: 21px;
    padding: 27px 55px;
    color: #fff;
    color: #fff;
    transition: all 0.3s ease;
}
.el-button:hover, .el-button:focus {
    color: #fff;
    background: #000;
    border-color: #000;
    box-shadow: 0 7px 12px -3px #000;
    transition: all 0.3s ease;
}
</style>
