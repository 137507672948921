import validate from "/home/ploi/zapisy.new.kayotravel.pl/node_modules/nuxt/dist/pages/runtime/validate.js";
import authentication_45global from "/home/ploi/zapisy.new.kayotravel.pl/middleware/authentication.global.ts";
import manifest_45route_45rule from "/home/ploi/zapisy.new.kayotravel.pl/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  authentication_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "login-access": () => import("/home/ploi/zapisy.new.kayotravel.pl/middleware/loginAccess.js"),
  auth: () => import("/home/ploi/zapisy.new.kayotravel.pl/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}