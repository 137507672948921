import { defineStore } from 'pinia'
import User from '@/utils/user-router'

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      BASE_URL: useRuntimeConfig().public.API_BASE_URL,
      user: {},
      isComplete: true
    }
  },
  actions: {
    async getUser (payload) {
      const token = useAuth().data?.value?.user?.accessToken || null
      const response = await User.getUser(
        token,
        this.BASE_URL
      )
      return response
    },

    async getUserPars () {
      const token = useAuth().data?.value?.user?.accessToken || null
      try {
        const response = await User.getUser(
          token,
          this.BASE_URL
        )
        return response
      } catch (error) {
        console.log(error)
      }
    },

    async updateUser (payload) {
      const token = useAuth().data?.value?.user?.accessToken || null
      const response = await User.updateUser(
        payload,
        token,
        this.BASE_URL
      )
      return response
    },

    setUser (data) {
      this.user = data.user
    },

    setUserComplete (data) {
      this.isComplete = data
    }
  }
})
