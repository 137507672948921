import { defineStore } from 'pinia'
import Trip from '@/utils/trip-router'

export const useTripStore = defineStore('tripStore ', {
  state: () => ({
    BASE_URL: useRuntimeConfig().public.API_BASE_URL,
    buyTripIdEnter: null,
    step: null,
    participants: [],
    resort: null,
    buyId: null
  }),

  actions: {
    async setBuyTripIdEnter (id) {
      this.buyTripIdEnter = id
      if (typeof window !== 'undefined') {
        localStorage.setItem('tripId', id)
      }
    },

    getBuyTripIdEnter () {
      if (typeof window !== 'undefined') {
        return localStorage.getItem('tripId')
      }
    },

    updateBuyTripIdEnter () {
      if (typeof window !== 'undefined') {
        this.buyTripIdEnter = localStorage.getItem('tripId')
      }
    },

    deleteBuyTripIdEnter () {
      if (typeof window !== 'undefined') {
        localStorage.setItem('tripId', '')
      }
      this.buyTripIdEnter = null
    },

    async getCurrentStateBuy () {
      const access_token = useAuth().data?.value?.user?.accessToken || null

      const response = await Trip.getCurrentStateBuy(
        access_token,
        this.BASE_URL
      )
      // Check enter url
      // if (this.buyTripIdEnter != response.data.wallet.buy.trip_id) {
      //   this.step = null
      //   await this.getTripStep({
      //     step: 1,
      //     trip_id: this.buyTripIdEnter
      //   })
      //   return
      // }

      this.step = response.data
      return response
    },

    async getTripStep (payload) {
      if (useAuth().status.value == 'unauthenticated') {
        useAuth().signOut({ callbackUrl: '/auth/login' })
      }
      const access_token = useAuth().data?.value?.user?.accessToken || null
      const response = await Trip.getTripStep(
        payload,
        access_token,
        this.BASE_URL
      )

      this.step = response.data

      return response
    },

    async getTripStepBack (payload) {
      const access_token = useAuth().data?.value?.user?.accessToken || null
      const response = await Trip.getTripStepBack(
        payload,
        access_token,
        this.BASE_URL
      )
      this.step = response.data

      return response
    },

    async deleteTripDiscount (payload) {
      const access_token = useAuth().data?.value?.user?.accessToken || null
      const response = await Trip.deleteTripDiscount(
        payload,
        access_token,
        this.BASE_URL
      )
      this.step.wallet = response.data
      return response
    },

    async getTripDiscount (payload) {
      const access_token = useAuth().data?.value?.user?.accessToken || null
      const response = await Trip.getTripDiscount(
        payload,
        access_token,
        this.BASE_URL
      )
      this.step.wallet = response.data
      return response
    },

    setBuyId (id) {
      this.buyId = id
    },

    addParticipant (user) {
      this.participants.push(user)
    },

    deleteParticipant (unique) {
      this.participants = this.participants.filter(function (ele) {
        return ele.unique != unique
      })
    },

    editParticipant (user) {
      this.participants = this.participants.map(u => u.unique !== user.unique ? u : user)
    },

    // RESORT
    setResort (id, price, available) {
      this.resort = { resort_id: id, price, booking_available: available }
    }

  },
  getters: {
    calcCost (state) {
      return state.step?.wallet.full_price || 0
    }
  }
})
